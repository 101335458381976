import React from 'react';
import {
  Button,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const MacskgBuilding = () => {
  return (
<Segment placeholder>
      <Grid columns={3} stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              <Icon name='file alternate outline' />
              Pre-trained LLM
            </Header>
            <Button secondary as={Link} to="/OpenAIPage">
            Generate rules
            </Button>
          </Grid.Column>

          <Grid.Column>
            <Header icon>
              <Icon name='eye dropper' />
              Manual rules editing
            </Header>
            <Button positive as={Link} to="/ManualRulePage">
            Start
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    
  );
};

export default MacskgBuilding;
