import React, { useState } from 'react';
import { Container, Popup, Modal, Segment, TextArea, Header, Button, Icon, Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import generateRule from '../service/GenerateRuleService';
import {sendRulesTotripleStore} from '../service/SparqlService';
import Instructions from './Instruction';
import { Link } from 'react-router-dom';

const RuleGenerator = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [processInput, setProcessInput] = useState('');
  const [productInput, setProductInput] = useState('');
  const [isResultTemplateActive, setisResultTemplateActive] = useState(false);
  const [process1Input, setProcess1Input] = useState('');
  const [process2Input, setProcess2Input] = useState('');
  const [isAfterTemplateActive, setisAfterTemplateActive] = useState(false);
  const [processInvInput, setProcessInvInput] = useState('');
  const [machineInput, setMachineInput] = useState('');
  const [isInvolvesTemplateActive, setisInvolvesTemplateActive] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const setTemplate = (e1, e2, e3) => {
    setisAfterTemplateActive(e1);
    setisResultTemplateActive(e2);
    setisInvolvesTemplateActive(e3);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      //handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (inputValue.trim()) {
      let newStr = inputValue;  // Use let for mutable variables
      if (inputValue.endsWith(".")) {
          newStr = inputValue.slice(0, -1);
      }
      setIsRequesting(true); // Set loading state before the API call
      console.log(inputValue);
      console.log(newStr);

      try {
        const result = await generateRule(newStr);
        setIsRequesting(false); // Unset loading state after the API call

        let newMessages = [...messages, { user: true, text: inputValue }];

        if (result.success && result.concreteRule && result.sparqlQuery && result.datalogRule) {
          // Combine the rule, SPARQL query, and datalog rule into one response
          const responses = [{
            rule: result.concreteRule,
            sparqlQuery: result.sparqlQuery,
            datalogRule: result.datalogRule
          }];

          // Push a new object with a responses array to the messages
          newMessages.push({ user: false, responses: responses });
        } else {
          newMessages.push({ user: false, text: `Error: ${result.error || 'Unknown MCSK format!'}` });
        }

        setMessages(newMessages);
        setInputValue('');
        setShowWelcome(false);
      } catch (error) {
        setIsRequesting(false); // Unset loading state after the API call in case of error
        let newMessages = [...messages, { user: true, text: inputValue }];
        newMessages.push({ user: false, text: `Error: ${error.message}` });
        setMessages(newMessages);
        setInputValue('');
        setShowWelcome(false);
      }
    }
  };

  const handleOpenInstructions = () => {
    setShowInstructionsModal(true);
  };

  const handleCloseInstructions = () => {
    setShowInstructionsModal(false);
  };

  const handleSparqlService = async (rule) => {
    const sparqlResult = await sendRulesTotripleStore(rule);
    if (sparqlResult.success) {
      alert('Success: Rule added to SPARQL service!');
    } else {
      alert(`Error: ${sparqlResult.error}`);
    }
  };

  const renderMessage = (message, index) => {
    if (message.user) {
      return (
        <Segment key={index} color='blue' style={{ alignSelf: 'flex-start' }}>
          {message.text}
        </Segment>
      );
    } else if (message.responses) {
      const validResponses = message.responses.filter(response => response.rule && response.sparqlQuery && response.datalogRule);

      if (validResponses.length > 0) {
        return (
          <div key={index} style={{ alignSelf: 'flex-end', marginTop: '1em' }}>
            {validResponses.map((response, idx) => (
              <Segment key={idx}>
                <p><strong>Rule:</strong> {response.rule}</p>
                <p><strong>SPARQL Query:</strong></p>
                <pre>{response.sparqlQuery}</pre>
                <p><strong>Datalog Rule:</strong></p>
                <pre>{response.datalogRule}</pre>
                <Button
                  onClick={() => handleSparqlService(response.rule)}
                  style={{ marginTop: '1em' }}
                  color='green'
                >
                  Send rule to SPARQL service
                </Button>
              </Segment>
            ))}
          </div>
        );
      } else {
        return (
          <Segment key={index} color='red' style={{ alignSelf: 'flex-end', color: 'red' }}>
            Unknown MCSK format!
            <Popup
              content="Click for instructions"
              trigger={
                <Icon
                  name="question circle"
                  size="large"
                  color='red'
                  style={{
                    position: 'absolute',
                    right: '60px',
                    top: '40%',
                    transform: 'translateY(-50%)',
                    padding: '10px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    marginLeft: '20px',
                    color: 'grey'
                  }}
                  onClick={handleOpenInstructions}
                />
              }
              position="left center"
            />
          </Segment>
        );
      }
    } else {
      return (
        <Segment key={index} color='grey' style={{ alignSelf: 'flex-end' }}>
          System response not available.
        </Segment>
      );
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container style={{ flex: '1' }}>
        {showWelcome && (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Header as='h1' textAlign='center' style={{ marginTop: '5%' }}>
             Manual Rule Page !
              <Segment.Inline>
                <Button positive as={Link} to='/'>
                  Back to Home
                </Button>
              </Segment.Inline>
            </Header>
            <Segment style={{ width: '90%', padding: '40px' }}>
              <Instructions />
              <div className="ui grid">
                <div className="three column row">
                  <div className="column">
                    <Button className="ui basic button"
                      onClick={() => setTemplate(false, true, false)}
                      style={{ marginTop: '2em', marginLeft: '1em' }}
                    >
                      Process Rule
                    </Button>
                  </div>
                  <div className="column">
                    <Button className="ui basic button"
                      onClick={() => setTemplate(true, false, false)}
                      style={{ marginTop: '2em', marginLeft: '1em' }}
                    >
                      Precedence Rule
                    </Button>
                  </div>
                  <div className="column">
                    <Button className="ui basic button"
                      onClick={() => setTemplate(false, false, true)}
                      style={{ marginTop: '2em', marginLeft: '1em' }}
                    >
                      Machine Rule
                    </Button>
                  </div>
                </div>
              </div>
              {(isAfterTemplateActive || isInvolvesTemplateActive || isResultTemplateActive) && (
                <h4 style={{ marginLeft: "11px" }}>Just fill in the blanks with your specific details, adhering to the format and you're all set.</h4>
              )}
            </Segment>
          </div>
        )}
        <div style={{ justifyContent: 'flex-end', padding: '1em', marginTop: '5%' }}>
          <div style={{ marginTop: '1%', overflowY: 'auto', overflowX: 'hidden', maxHeight: '55vh' }}>
            {messages.map(renderMessage)}
          </div>
          <div style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '80%',
            maxWidth: '800px',
          }}>
            <div style={{ position: 'relative' }}>
              {isResultTemplateActive && (
                <div className="ui segment"
                  style={{
                    fontSize: '1.2em',
                    padding: '1.5em 3em 1.5em 1.5em',
                    borderRadius: '20px',
                    border: '1px solid rgba(34,36,38,.15)',
                    width: '100%',
                    lineHeight: '1.21428571em',
                    resize: 'none',
                  }}>
                    <Popup
                    content="Enter a process"
                    trigger={
                      <input
                        type="text"
                        placeholder="Process"
                        value={processInput}
                        onChange={(e) => setProcessInput(e.target.value)}
                        style={{
                          marginRight: '00px',
                          border: 'none',
                          outline: 'none',
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid #ccc'
                        }} />}
                  />
                  needs to be performed for making 
                  <Popup
                    content="Enter a product"
                    trigger={<input
                      type="text"
                      placeholder="Product"
                      value={productInput}
                      onChange={(e) => setProductInput(e.target.value)}
                      style={{
                        marginRight: '10px',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid #ccc'
                      }} />} />.
                  <Button
                    onClick={() => {
                      setInputValue(`${processInput} needs to be performed for making a(n) ${productInput}.`);
                      setisResultTemplateActive(false);
                    }}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      marginLeft: '20px'
                    }}
                    icon="play"
                  />
                </div>
              )}

              {isAfterTemplateActive && (
                <div className="ui segment"
                  style={{
                    fontSize: '1.2em',
                    padding: '1.5em 3em 1.5em 1.5em',
                    borderRadius: '20px',
                    border: '1px solid rgba(34,36,38,.15)',
                    width: '100%',
                    lineHeight: '1.21428571em',
                    resize: 'none',
                  }}>
                  <Popup
                    content="Enter process1"
                    trigger={
                      <input
                        type="text"
                        placeholder="Process 1"
                        value={process1Input}
                        onChange={(e) => setProcess1Input(e.target.value)}
                        style={{
                          marginRight: '10px',
                          border: 'none',
                          outline: 'none',
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid #ccc'
                        }} />}
                  />
                  needs to be performed before <Popup
                    content="Enter process2"
                    trigger={<input
                      type="text"
                      placeholder="Process 2"
                      value={process2Input}
                      onChange={(e) => setProcess2Input(e.target.value)}
                      style={{
                        marginRight: '10px',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid #ccc'
                      }} />} />.
                  <Button
                    onClick={() => {
                      setInputValue(`${process1Input} needs to be performed before ${process2Input}.`);
                      setisAfterTemplateActive(false);
                    }}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      marginLeft: '20px'
                    }}
                    icon="play"
                  />
                </div>
              )}
              {isInvolvesTemplateActive && (
                <div className="ui segment"
                  style={{
                    fontSize: '1.2em',
                    padding: '1.5em 3em 1.5em 1.5em',
                    borderRadius: '20px',
                    border: '1px solid rgba(34,36,38,.15)',
                    width: '100%',
                    lineHeight: '1.21428571em',
                    resize: 'none',
                  }}>
                     <Popup
                    content="Enter a machine"
                    trigger={<input
                      type="text"
                      placeholder="Machine"
                      value={machineInput}
                      onChange={(e) => setMachineInput(e.target.value)}
                      style={{
                        marginRight: '10px',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid #ccc'
                      }} />} />
                  needs to be used in
                  <Popup
                    content="Enter a process"
                    trigger={
                      <input
                        type="text"
                        placeholder="Process"
                        value={processInvInput}
                        onChange={(e) => setProcessInvInput(e.target.value)}
                        style={{
                          marginRight: '10px',
                          border: 'none',
                          outline: 'none',
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid #ccc'
                        }} />}
                  />.
                  <Button
                    onClick={() => {
                      setInputValue(`${machineInput} needs to be used in the ${processInvInput}.`);
                      setisInvolvesTemplateActive(false);
                    }}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      marginLeft: '20px'
                    }}
                    icon="play"
                  />
                </div>
              )}
              <div style={{
                position: 'relative',
                paddingBottom: '15%', // Adjust this value as needed to avoid overlap with the footer
              }}>
                {!isAfterTemplateActive && !isResultTemplateActive && !isInvolvesTemplateActive && (
                  <>
                    <TextArea
                      placeholder="Enter your statement..."
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      style={{
                        fontSize: '1.2em',
                        padding: '1.5em 3em 1.5em 1.5em',
                        borderRadius: '20px',
                        border: '1px solid rgba(34,36,38,.15)',
                        width: '100%',
                        height: '75px',
                        lineHeight: '1.21428571em',
                        resize: 'none',
                      }}
                    />
                    <Button
                      icon="play"
                      // onClick={handleSubmit}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '20%',
                        transform: 'translateY(-50%)',
                        padding: '10px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                      }}
                    />
                  </>
                )}
              </div>
              {!showWelcome && (
                <Popup
                  content="Click for instructions"
                  trigger={
                    <Icon
                      name="question circle "
                      size="large"
                      style={{
                        position: 'absolute',
                        right: '60px',
                        top: '15%',
                        transform: 'translateY(-50%)',
                        padding: '10px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        marginLeft: '20px',
                        color: 'grey'
                      }}
                      onClick={handleOpenInstructions}
                    />
                  }
                  position="left center"
                />
              )}
              {showTemplateModal && (
                <Modal open={showTemplateModal} onClose={() => setShowTemplateModal(false)}>
                  <Modal.Header>Just fill in the blanks with your specific details, adhering to the format and you're all set.</Modal.Header>
                  <Modal.Content>
                    <p><Input type="text" placeholder="Process" onChange={(e) => setProcessInput(e.target.value)} /> needs to be performed for making <Input type="text" placeholder="Product" onChange={(e) => setProductInput(e.target.value)} />.</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => {
                      setInputValue(`${processInput} needs to be performed for making a(n) ${productInput}.`);
                      setShowTemplateModal(false);
                    }}>Submit</Button>
                  </Modal.Actions>
                </Modal>
              )}

              <Modal
                open={showInstructionsModal}
                onClose={handleCloseInstructions}
                size="small"
                dimmer="blurring"
              >
                <Modal.Header>Instructions</Modal.Header>
                <Modal.Content>
                  <Instructions />
                  <div className="ui grid">
                    <div className="three column row">
                      <div className="column"><Button className="ui basic button"
                        onClick={() => setTemplate(false, true)}
                        style={{ marginTop: '2em', marginLeft: '1em' }}
                      >
                        Process Rule
                      </Button></div>
                      <div className="column"><Button className="ui basic button"
                        onClick={() => setTemplate(true, false)}
                        style={{ marginTop: '2em', marginLeft: '1em' }}
                      >
                        Precedence Rule
                      </Button></div>
                      <div className="column"> <Button className="ui basic button"
                        onClick={() => setTemplate(false, false, true)}
                        style={{ marginTop: '2em', marginLeft: '1em' }}
                      >
                        Precedence Rule
                      </Button>
                      </div>
                    </div>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={handleCloseInstructions} color="blue">OK</Button>
                </Modal.Actions>
              </Modal>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RuleGenerator;
