import { API_BASE_URL } from '../config';
import axios from 'axios';

const OpenAIService = async (nl_input) => {
    try {
        const response = await fetch(`${API_BASE_URL}/openai/generate_rule`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ nl_input }),
        });
        const data = await response.json();
        if (response.ok) {
            return {
                success: true,
                response: data.response,
                rules: data.rules, 
                sparqlQueries: data.sparql_queries, 
                datalogRules: data.datalog_rules
            };
        } else {
            return { success: false, error: data.error || 'An error occurred' };
        }
    } catch (error) {
        return { success: false, error: error.message || 'An error occurred' };
    }
};

export default OpenAIService;