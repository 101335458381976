
import { API_BASE_URL } from '../config';

import axios from 'axios';

export const sendRulesTotripleStore = async (rule) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sparql/add_classes`, {
      expression: rule
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const sendMultiRulesToTripleStore = async (rules) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sparql/add_multiple_classes`, {
      expressions: rules
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const getAllGraphTriples = async (graphName) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sparql/get_all_graph_triples`, {
      params: {
        graph_name: graphName
      }
    });
    return response.data.triples;
  } catch (error) {
    console.error('Error fetching graph triples:', error);
    return [];
  }
};

