import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ManualRulePage from './pages/ManualRulePage';
import OpenAIPage from './pages/OpenAIPage';
import MacskgBuilding from './pages/MacskgBuilding';
import MacskgExplore from './pages/MacskgExplore';
import HomePage from './pages/HomePage';
import KG from './pages/KGVisualization';
import QueryKGPage from './pages/QueryKGPage';
import Footer from './component/Footer';
import Header from './component/Header';

const App = () => {
  return (
    <Router>
      <div>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Macskgexplore" element={<MacskgExplore />} />
        <Route path="/Macskgbuilding" element={<MacskgBuilding />} />
        <Route path="/ManualRulePage" element={<ManualRulePage />} />
        <Route path="/OpenAIPage" element={<OpenAIPage />} />
        <Route path="/KGVisualization" element={<KG />} />
        <Route path="/QueryKG" element={<QueryKGPage />} />
      </Routes>
      <Footer style={{ margin: 0 }} />
      </div>
    </Router>
  );
};

export default App;
