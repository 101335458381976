import React from 'react';
import { Container, Image, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../assets/MACS-KG6.png';

const Header = () => (
  <Menu inverted>
    <Container>
      <Menu.Item as={Link} to="/" header>
        <Image size="mini" src={logo} style={{ marginRight: '1.5em' }} />
        Manufacturing CommonSense Knowledge Graph
      </Menu.Item>
    </Container>
  </Menu>
);

export default Header;
