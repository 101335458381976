import React from 'react';
import {
  Button,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const MacskgExplore = () => {
  return (
<Segment placeholder>
      <Grid columns={3} stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              <Icon name='edit outline' />
              Query KG
            </Header>
            <Button secondary as={Link} to="/QueryKG">
              Start
            </Button>
          </Grid.Column>

          <Grid.Column>
            <Header icon>
              <Icon name='sitemap' />
              Knowledge Graph
            </Header>
            <Button positive as={Link} to="/KGVisualization">
            Visualize
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    
  );
};

export default MacskgExplore;
