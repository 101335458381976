import React, { useEffect, useState } from 'react';
import ValidationMessage from './ValidationMessage';

const SparqlValidator = ({ query, validationResult, handleQueryChange }) => {
  const [val, setVal] = useState(false);

  useEffect(() => {
    if (validationResult) {
      setVal(validationResult === 'The SPARQL query you entered is valid.');
      console.log('Validation UPDATES:', validationResult);
    }
  }, [validationResult]);

  return (
    <div>
      <textarea
        rows="10"
        cols="50"
        value={query}
        onChange={handleQueryChange}
        placeholder="Enter your SPARQL query here"
        style={{ width: '100%', maxWidth: '600px' }}
      ></textarea>
      <br />
      {query && validationResult && !val && (
        <ValidationMessage
        validationResult={validationResult}
        val={val}
      />
      )}
    </div>
  );
};

export default SparqlValidator;
