import React, { useState } from 'react';
import { Container, Button, Header, Segment, Icon, Modal, Table, Dropdown, Input, Message, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SparqlValidator from '../component/macskgExploringComponents/queryKgComponents/SparqlValidator'; 
import SparqlParser from 'sparqljs';
import * as XLSX from 'xlsx';
import { sparqlQyery } from '../service/nodeApi';

const QueryKGPage = () => {

  // States
  const [query, setQuery] = useState("select * where {?s ?p ?o }");
  const [validationResult, setValidationResult] = useState('');
  const [results, setResults] = useState([]);
  const [filteredResultsTable, setFilteredResultsTable] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [displayMode, setDisplayMode] = useState('table');
  const [filterText, setFilterText] = useState('');

  // handle input changes
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    validateQuery(event.target.value);
  };

  const validateQuery = (query) => {
    const parser = new SparqlParser.Parser();
    try {
      parser.parse(query);
      setValidationResult('The SPARQL query you entered is valid.');
    } catch (error) {
      setValidationResult('Invalid SPARQL query, check syntax.');
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(results);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'result');
    XLSX.writeFile(workbook, 'result.xlsx');
  };

  // Send query to Node.js server
  const sendQueryToServer = async () => {
    try {
      const data = await sparqlQyery(query);
      setResults(data);
      setFilteredResultsTable(data);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error sending query:', error);
      setResults([{ error: error.message }]);
      setFilteredResultsTable([{ error: error.message }]);
      setModalIsOpen(true);
    }
  };

  const renderTable = () => {
    if (filteredResultsTable.length === 0) return <Message>No results found.</Message>;
    
    const headers = Object.keys(filteredResultsTable[0]).map(key => (
      <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
    ));

    const rows = filteredResultsTable.map((row, rowIndex) => (
      <Table.Row key={rowIndex}>
        {Object.values(row).map((value, colIndex) => (
          <Table.Cell key={colIndex}>{value}</Table.Cell>
        ))}
      </Table.Row>
    ));

    return (
      <>
        <Message>
          <Message.Header>Showing {filteredResultsTable.length} results</Message.Header>
        </Message>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {headers}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows}
          </Table.Body>
        </Table>
      </>
    );
  };

  const renderModalContent = () => {
    if (displayMode === 'json') {
      return <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(results, null, 2)}</pre>;
    } else {
      if (results.length === 0) {
        return <Message>No results found.</Message>;
      }
      return (
        <>
          <Input
            icon='search'
            placeholder='Filter results...'
            value={filterText}
            onChange={(e) => handleFilterChange(e.target.value)}
            style={{ marginBottom: '1em' }}
          />
          {renderTable()}
        </>
      );
    }
  };

  const handleDisplayModeChange = (e, { value }) => setDisplayMode(value);

  const handleFilterChange = (text) => {
    setFilterText(text);
    const lowercasedFilter = text.toLowerCase();
    const filteredData = results.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedFilter)
      )
    );
    setFilteredResultsTable(filteredData);
  };

  return (
    <Container text style={{ marginTop: '3em' }}>
      <Segment placeholder>
        <SparqlValidator 
          query={query} 
          validationResult={validationResult} 
          handleQueryChange={handleQueryChange} 
        />
        <Segment.Inline>
          <Button 
            color='black' 
            onClick={sendQueryToServer} 
            disabled={!query || validationResult !== 'The SPARQL query you entered is valid.'}
          >
            Query
          </Button>
        </Segment.Inline>
        <Segment.Inline>
          <Button positive as={Link} to='/'>
            Back to Home page
          </Button>
        </Segment.Inline>
        <Modal
          onClose={() => setModalIsOpen(false)}
          onOpen={() => setModalIsOpen(true)}
          open={modalIsOpen}
          size='large'
          style={{ overflow: 'auto', maxHeight: '90vh' }}
        >
          <Header icon>
            <Icon name='archive' />
            SPARQL Query Response
          </Header>
          <Grid centered>
            <Button onClick={exportToExcel} color='green' style={{ marginTop: '1em' }}>Export to Excel</Button>
          </Grid>

          <Modal.Content>
            <Dropdown
              placeholder='Select Display Mode'
              fluid
              selection
              options={[
                { key: 'table', text: 'Table', value: 'table' },
                { key: 'json', text: 'JSON', value: 'json' },
              ]}
              onChange={handleDisplayModeChange}
              value={displayMode}
              style={{ marginBottom: '1em' }}
            />
            {renderModalContent()}
          </Modal.Content>
          <Modal.Actions>
            <Button basic color='red' inverted onClick={() => setModalIsOpen(false)}>
              <Icon name='remove' /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </Container>
  );
};

export default QueryKGPage;
