import React, { useState } from 'react';
import { Container, Segment, Image, Grid } from 'semantic-ui-react';
import RuleGenerator from '../component/RuleGenerator';

const HomePage = () => {
  const [responses, setResponses] = useState([]);

  const handleGenerateRule = (inputValue) => {
    setResponses([...responses, inputValue]);
  };

  return (
    <div>
      <RuleGenerator onGenerateRule={handleGenerateRule} />
      {responses.map((response, index) => (
        <Segment key={index}>
          <strong>User:</strong> {response}
        </Segment>
      ))}
    </div>
  );
};



export default HomePage;
