import React, { useState } from 'react';
import { Container, Segment, TextArea, Header, Button, Checkbox, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import OpenAIService from '../service/OpenAIService';
import { sendMultiRulesToTripleStore } from '../service/SparqlService';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const RuleGeneratorOpenai = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [statements, setStatements] = useState([]);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [validations, setValidations] = useState({}); // State to manage the validation status

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (inputValue.trim()) {
      setIsRequesting(true); // Set loading state before the API call
      const result = await OpenAIService(inputValue);
      setIsRequesting(false); // Unset loading state after the API call

      let newMessages = [...messages, { user: true, text: inputValue }];
      if (result.success) {
        const responses = result.rules.map((rule, index) => ({
          rule: rule,
          sparqlQuery: result.sparqlQueries[index],
          datalogRule: result.datalogRules[index],
        }));

        // Extract and set statements from the response if it exists
        const extractedStatements = result.response ? result.response.split('\n').filter(statement => statement.trim() !== '') : [];
        setStatements(extractedStatements);

        newMessages.push({ user: false, responses: responses });
      } else {
        newMessages.push({ user: false, text: `Error: ${result.error}` });
      }

      setMessages(newMessages);
      setInputValue('');
      setShowWelcome(false);
    }
  };

  const handleValidationChange = (index, checked) => {
    setValidations({
      ...validations,
      [index]: checked
    });
  };

  const handleSendToKG = async () => {
    const checkedRules = messages.flatMap(message => 
      message.responses ? message.responses.filter((_, idx) => validations[idx]).map(response => response.rule) : []
    );

    const sparqlResult = await sendMultiRulesToTripleStore(checkedRules);
    if (sparqlResult.success) {
      alert('Success: Rule added to SPARQL service!');
    } else {
      alert(`Error: ${sparqlResult.error}`);
    }
  };
  const renderMessage = (message, index) => {
    if (message.user) {
      return (
        <Segment key={index} color='blue' style={{ alignSelf: 'flex-start' }}>
          {message.text}
        </Segment>
      );
    } else if (message.responses) {
      const validResponses = message.responses.filter(response => response.rule && response.sparqlQuery && response.datalogRule);

      if (validResponses.length > 0) {
        return (
          <div key={index} style={{ overflowX: 'auto', width: '100%' }}>
            <Segment style={{ marginTop: '1em' }}>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <strong>Requirements:</strong>
                {statements.map((statement, idx) => (
                  <div key={idx}>{statement}</div>
                ))}
              </div>
              <Table celled compact="very" size="small" style={{ marginTop: '1em', width: '100%' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell><strong>Rule</strong></Table.Cell>
                    <Table.Cell><strong>Validate</strong></Table.Cell>
                  </Table.Row>
                  {validResponses.map((response, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell style={{ whiteSpace: 'pre-wrap' }}>
                        <pre style={{ margin: 0 }}>{response.rule}</pre>
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={!!validations[idx]}
                          onChange={(e, { checked }) => handleValidationChange(idx, checked)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <strong>SPARQL Queries:</strong>
                      {validResponses.map((response, idx) => (
                        <div key={idx}><pre>{response.sparqlQuery}</pre><br /></div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan='2'>
                      <strong>Datalog Rules:</strong>
                      {validResponses.map((response, idx) => (
                        <div key={idx}><pre>{response.datalogRule}</pre><br /></div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                  {isAnyCheckboxChecked() && (
                    <Table.Row>
                      <Table.Cell colSpan='2' textAlign='center'>
                        <Button positive onClick={handleSendToKG}>
                        Send rules to SPARQL service
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Segment>
          </div>
        );
      } else {
        return (
          <Segment key={index} color='red' style={{ alignSelf: 'flex-end', color: 'red' }}>
            Unknown MCSK format!
          </Segment>
        );
      }
    } else {
      return (
        <Segment key={index} color='grey' style={{ alignSelf: 'flex-end' }}>
          System response not available.
        </Segment>
      );
    }
  };

  const isAnyCheckboxChecked = () => {
    return Object.values(validations).some(value => value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {showWelcome ? (
          <div>
            <Header as='h1' textAlign='center'>
              Automatic Generation of rules using pre-trained LLM!
              <Segment.Inline>
                <Button positive as={Link} to='/'>
                  Back to Home
                </Button>
              </Segment.Inline>
            </Header>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <TextArea
                placeholder="MCSK about ..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{
                  fontSize: '1.2em',
                  padding: '1.5em 3em 1.5em 1.5em',
                  borderRadius: '20px',
                  border: '1px solid rgba(34,36,38,.15)',
                  width: '100%',
                  height: '75px',
                  lineHeight: '1.21428571em',
                  resize: 'none',
                  margin: '130px'
                }}
              />
              <Button
                icon="play"
                onClick={handleSubmit}
                loading={isRequesting}
                style={{
                  marginLeft: '-200px',
                  padding: '10px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
              />
            </div>
            <Footer style={{ position: 'absolute', bottom: '200px', width: '100%' }} />
          </div>
        ) : (
          <>
            <div style={{ justifyContent: 'flex-end', padding: '1em', marginTop: '5%' }}>
              <div style={{ marginTop: '1%', overflowY: 'auto', overflowX: 'hidden', maxHeight: '70vh', width: '100%' }}>
                {messages.map(renderMessage)}
              </div>
              <div style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', width: '80%', maxWidth: '1000px' }}>
                <div style={{ position: 'relative' }}>
                  <TextArea
                    placeholder="MCSK about ..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    style={{
                      fontSize: '1.2em',
                      padding: '1.5em 3em 1.5em 1.5em',
                      borderRadius: '20px',
                      border: '1px solid rgba(34,36,38,.15)',
                      width: '100%',
                      height: '75px',
                      lineHeight: '1.21428571em',
                      resize: 'none',
                    }}
                  />
                  <Button
                    icon="play"
                    onClick={handleSubmit}
                    loading={isRequesting}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default RuleGeneratorOpenai;
