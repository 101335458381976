import React from 'react';

const Instructions = () => {
  return (
    <div>
      <ul className="ui list instructions-list">  
        <h4>To get the most out of our tool, you can use the following formats to structure your Commonsense Knowledge statements to convert them in to semantic Rules with alignment to Basic Formal Ontology (BFO) and Industrial Ontology Foundry (IOF) .</h4>
        <h5>For example:</h5>
        <div className="item">CSK Statement about Process Output: "[Process] needs to be performed for making [Product]." (Example: "Paintingprocess needs to be performed for making a Picture.")</div>
        <div className="item">CSK Statement about Process Sequence: "[Process1] needs to be performed before [Process2]." (Example: "PainitngProcess needs to be performed before DryingProcess.")</div>
        <div className="item">CSK Statement Machine Involvement: "[Machine] needs to be used in [Process]." (Example: "Brush needs to be used in Paintingprocess.")</div>
        {/* <h4>You can input multiple statements in one go—just separate them with a period (.) or by starting a new line for each.</h4> */}
      </ul>
    </div>
  );
};

export default Instructions;
