import React from 'react';
import { Container, Segment, Grid } from 'semantic-ui-react';
import logo from '../assets/logo.png';
import ANR from '../assets/ANR.png';

const Footer = () => (
  <Container >
<Segment style={{ margin: '2em 0em 0em', padding: '1em 0em', boxShadow: 'none', backgroundColor: '#fff', border:"none" }} textAlign='center'>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            {/* Adjust the width (and/or height) as needed */}
            <img src={logo} alt="Logo" style={{ maxWidth: '140px', marginTop: '10px' }} />
          </Grid.Column>
          <Grid.Column width={8}>
            {/* Adjust the width (and/or height) as needed */}
            <img src={ANR} alt="ANR" style={{ maxWidth: '50px' }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ marginTop: '5px' }}>
        This project has received funding from the French National Research Agency ANR under grant agreement no. ANR-21-CE10-0004-01
      </div>
    </Segment>
  </Container>
);

export default Footer;
