import axios from 'axios';

export const sparqlQyery = async (query) => {
  try {
    const url = process.env.REACT_APP_NODE_APP;
    console.log(url);
    const response = await axios.post(`${url}/query`, { query });
    return response.data;
  } catch (error) {
    console.error('Error sending query:', error);
    throw new Error(error.response ? error.response.data : 'Server error');
  }
};
