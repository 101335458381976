import React from 'react';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Search,
  Segment,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import welcomeImage from '../assets/MACS-KG5.png';

const HomePage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header as='h2' textAlign='center' style={{ fontSize: '2.5em', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        Welcome to MACS-KG!
      </Header>
      <p style={{ fontSize: '1.5em', fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center' }}>
        Uncover the endless possibilities at MACS-KG , where exploration meets innovation. Start your journey with us today!
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '20px 0' }}>
        <img src={welcomeImage} alt="Welcome Image" style={{ maxWidth: '25%', height: 'auto' }} />
      </div>
<Segment placeholder>
      <Grid columns={2} stackable textAlign='center'>
         <Divider vertical>Or</Divider> 
        
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              <Icon name='server' />
               <strong>MACS-KG</strong>  <br/>Building
            </Header>
            <Button secondary as={Link} to="/Macskgbuilding">
              Go
            </Button>
          </Grid.Column>

          <Grid.Column >
            <Header icon>
              <Icon name='server' />
              <strong>MACS-KG</strong>  <br/>Exploring
            </Header>
            <Button positive as={Link} to="/Macskgexplore">
              Browse
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    </div>
  );
};

export default HomePage;
