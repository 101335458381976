import React, { useEffect, useState }  from 'react';
import cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';

import {getAllGraphTriples} from '../service/SparqlService';


import CytoscapeGraph from '../component/CytoscapeGraph';


import '../assets/css/KGVisualization.css'

cytoscape.use(fcose);

const extractLabel = (uri) => {
  const hashIndex = uri.lastIndexOf('#');
  const slashIndex = uri.lastIndexOf('/');

  if (hashIndex !== -1) {
    return uri.substring(hashIndex + 1);
  } else if (slashIndex !== -1) {
    return uri.substring(slashIndex + 1);
  } else {
    return uri; // return the entire URI if neither '#' nor '/' is found
  }
};


const KGVisualization = () => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const triples = await getAllGraphTriples('http://mcsk.enit.fr/chaikmat');
        // Convert triples to elements
        const nodes = {};
        const edges = [];

        triples.forEach((triple, index) => {
          // Add subject as node if it doesn't exist
          if (!nodes[triple.subject]) {
            nodes[triple.subject.value] = { data: { id: triple.subject.value, label: extractLabel(triple.subject.value), type: 'class' } };
          }

          // Add object as node if it doesn't exist
          if (!nodes[triple.object]) {
            nodes[triple.object.value] = { data: { id: triple.object.value, label: extractLabel(triple.object.value), type: 'class' } };
          }

          // Add edge
          edges.push({
            data: {
              id: `${triple.subject.value}-${triple.predicate.value}-${triple.object.value}`,
              source: triple.subject.value,
              target: triple.object.value,
              label: extractLabel(triple.predicate.value), // Extract label from URI
              type: 'relation' // Assuming all predicates are relations
            }
          });
        });

        // Combine nodes and edges into elements array
        const graphElements = Object.values(nodes).concat(edges);
        setElements(graphElements);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="graph-div">
      <CytoscapeGraph elements={elements} />
    </div>
  );
};

export default KGVisualization;
