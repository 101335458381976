import React, { useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import { CiCircleInfo, CiCircleRemove } from "react-icons/ci";
import { IoCopyOutline, IoClose } from "react-icons/io5";

import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';



cytoscape.use(fcose);

const CytoscapeGraph = ({ elements }) => {
  const cyRef = useRef(null);
  const [hoverNode, setHoverNode] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [nodeInfo, setNodeInfo] = useState({ id: '', label: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showClearIcon, setShowClearIcon] = useState(false);

  useEffect(() => {
    if (cyRef.current) {
      cyRef.current.destroy();
    }

    cyRef.current = cytoscape({
      container: document.getElementById('cy'),
      elements: elements,
      style: [
        {
          selector: 'node',
          style: {
            'label': 'data(label)',
            'text-wrap': 'wrap',
            'text-max-width': '100px',
            'text-halign': 'center',
            'text-valign': 'center',
            'background-color': function(ele) {
              const type = ele.data('type');
              switch (type) {
                case 'class': return '#ff8080';
                case 'individual': return '#fed684';
                default: return '#666';
              }
            }
          }
        },
        {
          selector: 'node.highlighted',
          style: {
            'label': 'data(label)',
            'text-wrap': 'wrap',
            'text-max-width': '100px',
            'text-halign': 'center',
            'text-valign': 'center',
            'background-color': function(ele) {
              const type = ele.data('type');
              switch (type) {
                case 'class': return '#ff4242';
                case 'individual': return '#fed684';
                default: return '#666';
              }
            }
          }
        },
        {
          selector: 'node.neighboor',
          style: {
            'label': 'data(label)',
            'text-wrap': 'wrap',
            'text-max-width': '100px',
            'text-halign': 'center',
            'text-valign': 'center',
            'background-color': function(ele) {
              const type = ele.data('type');
              switch (type) {
                case 'class': return '#ff8080';
                case 'individual': return '#fed684';
                default: return '#666';
              }
            }
          }
        },
        {
          selector: 'node.semitransp',
          style: {
            'label': 'data(label)',
            'text-wrap': 'wrap',
            'text-max-width': '100px',
            'text-halign': 'center',
            'text-valign': 'center',
            'opacity': '0.5',
            'background-color': function(ele) {
              const type = ele.data('type');
              switch (type) {
                case 'class': return '#ff8080';
                case 'individual': return '#fed684';
                default: return '#666';
              }
            }
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 3,
            'line-color': '#ddd',
            'target-arrow-color': '#ddd',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'label': 'data(label)',
            'font-size': '12px',
            'text-rotation': 'autorotate',
            'text-margin-x': '10px'
          }
        },
        {
          selector: 'edge.semitransp',
          style: {
            'width': 3,
            'line-color': '#ddd',
            'target-arrow-color': '#ddd',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'label': 'data(label)',
            'font-size': '12px',
            'text-rotation': 'autorotate',
            'text-margin-x': '10px',
            'opacity': '0.5'
          }
        },
        {
          selector: 'edge.highlighted',
          style: {
            'width': 3,
            'line-color': '#000',
            'target-arrow-color': '#000',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'label': 'data(label)',
            'font-size': '12px',
            'text-rotation': 'autorotate',
            'text-margin-x': '10px'
          }
        }
        // ,
        // {
        //   selector: '.highlighted',
        //   style: {
        //     'background-color': '#ffeb3b',
        //     'line-color': '#ffeb3b',
        //     'target-arrow-color': '#ffeb3b',
        //     'transition-property': 'background-color, line-color, target-arrow-color',
        //     'transition-duration': '0.5s'
        //   }
        // }
      ],
      layout: {
        name: 'fcose',
        animate: true,
        fit: true,
        padding: 30,
        randomize: true,
        gravity: 1,
        nodeDimensionsIncludeLabels: true,
        tile: true,
      }
    });

    const cy = cyRef.current;

    cy.on('mouseover', 'node', (event) => {
      setHoverNode(event.target);
    });

    cy.on('mouseout', 'node', () => {
      setHoverNode(null);
    });

  }, [elements]);

  const handleShowInfo = (node) => {
    setNodeInfo({ id: node.data('id'), label: node.data('label') });
    setDialogVisible(true);
  };

  const handleRemoveNode = (node) => {
    cyRef.current.remove(node);
  };

  const handleCopyIRI = (node) => {
    navigator.clipboard.writeText(node.data('id'));
    alert('IRI copied to clipboard');
  };

  const getIconPosition = (angle, radius) => ({
    top: `${hoverNode.renderedPosition().y + radius * Math.sin(angle)}px`,
    left: `${hoverNode.renderedPosition().x + radius * Math.cos(angle)}px`
  });

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('URI copied to clipboard');
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setShowClearIcon(query !== '');
    if (query) {
      const filteredNodes = cyRef.current.nodes().filter(node => node.data('label').toLowerCase().includes(query.toLowerCase()));
      setSuggestions(filteredNodes.map(node => node.data()));
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (nodeData) => {
    const node = cyRef.current.getElementById(nodeData.id);
    const neighboors = node.neighborhood();
    cyRef.current.elements().removeClass('highlighted');
    cyRef.current.elements().addClass('semitransp');
    node.addClass('highlighted').outgoers().addClass('highlighted');
    node.addClass('highlighted').neighborhood().addClass('neighboor');
    console.log(node.outgoers())
    console.log(neighboors)
    node.removeClass('semitransp').neighborhood().removeClass('semitransp')
    node.connectedEdges().addClass('highlighted');
    
    cyRef.current.animate({
      fit: {
        eles: node.closedNeighborhood(),
        padding: 50
      },
      duration: 500
    });
    
    setSuggestions([]);
    setSearchQuery(nodeData.id);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    resetGraph(); // Reset graph when clear icon is clicked
  };

  const resetGraph = () => {
    cyRef.current.elements().removeClass('highlighted');
    cyRef.current.elements().removeClass('semitransp');
  };

  return (
    <div style={{ position: 'relative', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.06)', borderRadius: '8px', padding: '10px' }}>
    <div style={{ position: 'relative' }}>
        <Input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search..."
          style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
        />
        {showClearIcon && (
          <button
            onClick={handleClearSearch}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: '#aaa',
            }}
          >
            <IoClose style={{ fontSize: '20px' }} />
          </button>
        )}
      </div>
      <List style={{ position: 'absolute', background: 'white', zIndex: 1000, listStyle: 'none', padding: 0, margin: 0, width:'98%'}}>
        {suggestions.map(suggestion => (
          <ListItem
            key={suggestion.id}
            onClick={() => handleSuggestionClick(suggestion)}
            style={{ padding: '8px', cursor: 'pointer' /*, borderBottom: '1px solid #ddd' */}}
          >
            {suggestion.label}
          </ListItem>
        ))}
      </List>
      <div id="cy" style={{ width: '90vw', height: '75vh' }}></div>
      {hoverNode && (
        <>
          <button
            onClick={() => handleShowInfo(hoverNode)}
            style={{
              ...getIconPosition(-Math.PI / 4, 50), // Adjust angle and radius
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              background: '#eeeeee', // circle background color
              borderRadius: '50%', // make it a circle
              border: 'none',
              width: '30px', // adjust size
              height: '30px', // adjust size
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#ad2400', // icon color
              fontSize: '20px', // icon size
              zIndex: 10,
            }}
          >
            <CiCircleInfo />
          </button>
          <button
            onClick={() => handleRemoveNode(hoverNode)}
            style={{
              ...getIconPosition(0, 50), // Adjust angle and radius
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              background: '#eeeeee', // circle background color
              borderRadius: '50%', // make it a circle
              border: 'none',
              width: '30px', // adjust size
              height: '30px', // adjust size
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#ad2400', // icon color
              fontSize: '20px', // icon size
              zIndex: 10,
            }}
          >
            <CiCircleRemove />
          </button>
          <button
            onClick={() => handleCopyIRI(hoverNode)}
            style={{
              ...getIconPosition(Math.PI / 4, 50), // Adjust angle and radius
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              background: '#eeeeee', // circle background color
              borderRadius: '50%', // make it a circle
              border: 'none',
              width: '30px', // adjust size
              height: '30px', // adjust size
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#ad2400', // icon color
              fontSize: '20px', // icon size
              zIndex: 10,
            }}
          >
            <IoCopyOutline />
          </button>
        </>
      )}
      {dialogVisible && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          zIndex: 1000,
        }}>
          <h2>{nodeInfo.label}</h2>
          <p>
            {nodeInfo.id}
            <button onClick={() => copyToClipboard(nodeInfo.id)} style={{
              marginLeft: '10px',
              padding: '5px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: '#ad2400',
              fontSize: '20px',
            }}>
              <IoCopyOutline />
            </button>
          </p>
          <button onClick={closeDialog} style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: 'green',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}>Close</button>
        </div>
      )}
      {dialogVisible && (
        <div onClick={closeDialog} style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }} />
      )}
    </div>
  );
};

export default CytoscapeGraph;
