import React from 'react';
import { Message } from 'semantic-ui-react';

const ValidationMessage = ({ validationResult, val }) => {
  return (
    <Message
      success={val}
      header='Query Validator'
      content={validationResult}
    />
  );
};

export default ValidationMessage;